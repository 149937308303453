<template>
  <div class="return-up-route">
    <span class="back" @click="backBtnClick">
      <a-icon class="margin-r6" type="arrow-left" />
      返回
    </span>
    <span class="margin-r6 margin-l8 color-blue">|</span>
    <span class="color-blue">{{ pageName }}</span>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageName: this.name,
    }
  },
  watch: {
    $route: {
      handler(v) {
        // 注意顺序，先跳转再改变页面名称！
        if (!this.name) {
          if (this.pageName) {
            setTimeout(() => {
              this.pageName = v.meta.title
            }, 200)
          } else {
            this.pageName = v.meta.title
          }
        }
      },
      immediate: true,
    },
    name: {
      handler(v) {
        this.pageName = v
      },
    },
  },
  methods: {
    backBtnClick() {
      if (this.$listeners.backBtnClick) {
        this.$emit('backBtnClick')
      } else {
        // 添加了一次 空 history
        this.$router.back()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.return-up-route {
  height: 16px;
  left: 20px;
  top: 0px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  flex: none;
  order: 1;
  align-self: center;
  margin: 4px 0px 36px;
  .margin-r6 {
    margin-right: 6px;
  }
  .color-blue {
    color: #2d63ff;
  }
  .margin-l8 {
    margin-left: 8px;
  }
  .back {
    cursor: pointer;
  }
}
</style>
